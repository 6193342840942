/* メニューコンテナ */
.menu-container {
    flex: 0 0 200px; /* 固定幅 */
    height: 100vh; /* メニューを全画面の高さに */
    background-color: #f9f9f9;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); /* サイドメニューに影を追加 */
  }
  
  /* 全体コンテナ */
  .dashboard-container {
    display: flex; /* フレックスボックスでレイアウト */
    font-family: 'NotoSansJP', sans-serif;
    min-height: 100vh; /* 全画面の高さを確保 */
  }
  
  /* ダッシュボードのコンテンツ */
  .dashboard-content {
    flex-grow: 1; /* 残りのスペースを占有 */
    padding: 20px;
    font-family: 'NotoSansJP', sans-serif;
    background-color: #fff;
    overflow-x: auto;
  }
  
  /* 見出しのスタイル */
  .dashboard-content h1 {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
    color: #2c3e50;
    font-size: 24px;
    font-weight: bold;
    border-bottom: 2px solid #ecf0f1;
    padding-bottom: 10px;
  }
  
  /* テーブルのスタイル */
  .table {
    width: 100%;
    border-collapse: collapse;
    font-family: 'NotoSansJP', sans-serif;
    margin-top: 20px;
  }
  
  .table th,
  .table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .table th {
    background-color: #f7f7f7;
    font-weight: bold;
  }
  
  .table tr:hover {
    background-color: #f5f5f5;
  }
  
  /* ボタンのスタイル */
  .button-link {
    display: inline-block;
    padding: 10px 16px;
    background-color: #6FC9F2;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    transition: background-color 0.3s ease;
  }
  
  .button-link:hover {
    background-color: #4EABD9;
  }
  