.notfound-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  max-width: 1000px;
  margin: 40px auto;
  background-color: #ffffff;
  font-family: 'NotoSansJP', sans-serif; /* フォントを統一 */
}

.notfound-message {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
}

.notfound-message h1 {
  font-size: 24px;
  font-weight: bold;
  color: #222;
  margin-bottom: 20px;
}

.notfound-message p {
  font-size: 16px;
  color: #444;
  margin-bottom: 20px;
}

.notfound-message button {
  display: inline-block;
  padding: 12px 20px;
  background-color: #5c67f2;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  font-family: 'NotoSansJP', sans-serif;
}

.notfound-message button:hover {
  background-color: #5058d4;
}

.notfound-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.notfound-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px; /* 角丸効果 */
}
