/* Footer.css */
.footer {
width: 100%;
background: #3B7A91;
color: white;
padding: 2rem 0;
position: relative;
margin-top: auto;
font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.footer a {
text-decoration: none;
color: white;
font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.footer-content {
display: flex;
justify-content: flex-start;
gap: 2rem;
flex-wrap: nowrap;
margin: 0 2rem;
font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.footer-section {
flex: 1;
margin-right: 0.5rem;
}

.footer-section ul {
list-style-type: none;
padding: 0;
}

.footer-section ul li {
margin-bottom: 0.25rem;
}

.footer-bottom {
text-align: center;
margin-top: 1rem;
font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}
