/* Fileupload.css */
.file-upload-container {
    display: flex;
    min-height: 100vh; /* 全画面表示の高さを確保 */
    font-family: 'NotoSansJP', sans-serif;
  }
  
  .menu {
    width: 200px;
    flex-shrink: 0;
    font-family: 'NotoSansJP', sans-serif;
  }
  
  .file-upload-content {
    flex-grow: 1;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
  }
  
  .file-upload-title {
    text-align: center;
    margin: 20px 0;
    color: #2c3e50;
    font-size: 24px;
    font-weight: bold;
    border-bottom: 2px solid #ecf0f1;
    padding-bottom: 10px;
  }
  
  .file-upload-form {
    margin-top: 20px;
  }
  
  .description-container,
  .file-input-container {
    margin-bottom: 20px;
  }
  
  input[type="text"],
  input[type="file"] {
    display: block;
    width: 100%;
    padding: 10px;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    font-size: 16px;
  }
  
  .upload-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    transition: background-color 0.3s ease;
  }
  
  .upload-button:hover {
    background-color: #0056b3;
  }
  
  .upload-button.disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .error-message {
    color: red;
    margin-top: 10px;
  }
  
  .upload-success-message {
    margin-top: 20px;
    color: green;
    font-weight: bold;
  }
  