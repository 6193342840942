/* HowToUse.css */
.how-to-use-container {
display: flex;
flex-direction: row;
flex: 1;
font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.how-to-use-content {
display: flex;
flex-direction: column;
flex: 1;
overflow-y: auto;
font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.how-to-use-main-content {
flex: 1;
padding: 20px;
font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.how-to-use-title {
text-align: center;
margin-top: 10px;
margin-bottom: 20px;
color: #2c3e50;
font-size: 24px;
font-weight: bold;
padding: 5px;
border-radius: 8px;
font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.menu {
width: 200px;
font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

section {
margin-bottom: 3rem;
font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

h2 {
font-size: 20px;
margin-bottom: 1.5rem;
color: #34495e;
border-bottom: 2px solid #bdc3c7;
padding-bottom: 5px;
font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

h3 {
font-size: 18px;
margin-bottom: 1rem;
color: #2c3e50;
font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

ul {
list-style-type: disc;
margin-left: 1.5rem;
font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

li {
margin-bottom: 0.5rem;
font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

p {
margin-bottom: 1rem;
font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.note {
font-size: 0.9em; /* ワンサイズ小さく */
color: #7f8c8d;  /* 色を薄く */
font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}
