/* Navbar.css */
.navbar {
  padding: 20px;
  background: #f0f0f0;
  display: flex;
  align-items: center;
  gap: 20px;
  font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.navbar-logo {
  height: 50px; /* ロゴのサイズ調整 */
}

.navbar a {
  color: blue; /* リンクの基本色 */
  text-decoration: none; /* 下線を除去 */
  padding: 0 10px; /* リンク間の余白 */
  font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.navbar a.active {
  color: red; /* アクティブなリンクの色 */
}