/* Answer.css */
.menu {
width: 200px;
padding: 0%;
font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.layout {
display: flex;
flex-grow: 1;
font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.answer-page {
display: flex;
font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.answer-content {
background-color: #ffffff;
padding: 20px;
border-radius: 8px;
width: calc(100% - 240px); /* メニューの幅を引いたコンテンツ幅 */
font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.answer-content > h1 {
text-align: center;
margin-top: 10px;
margin-bottom: 20px;
color: #2c3e50; /* 見出しの文字色 */
font-size: 24px; /* 文字サイズを少し大きくする */
font-weight: bold;
padding: 5px; /* 見出しの上下に余白を追加 */
border-radius: 8px; /* 角を丸くする */
font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.answer-content > h2 {
text-align: center;
margin-top: 30px;
margin-bottom: 15px;
color: #444;
font-size: 18px; /* フォントサイズをh1より小さく設定 */
font-weight: bold;
font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.file-upload-history,
.admin-answers {
margin-bottom: 40px;
font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.file-upload-history h2,
.admin-answers h2 {
background-color: #ecf0f1;
margin: 0;
padding: 10px;
border-top: 1px solid #ddd;
border-bottom: 2px solid #ddd;
text-align: left;
font-size: 15px; /* フォントサイズをh1より小さく設定 */
font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.file-upload-history ul,
.admin-answers ul {
list-style: none;
padding: 0;
margin: 0;
width: 100%;
border-collapse: collapse;
font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.file-upload-history li,
.admin-answers li {
display: flex;
border-bottom: 1px solid #ddd;
padding: 10px;
font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.file-upload-history li span,
.admin-answers li span {
flex: 1;
text-align: left;
padding-right: 10px;
font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.file-upload-history li span:last-child,
.admin-answers li span:last-child {
text-align: right;
font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}