/* Menu.css */
.menu {
display: flex;
flex-direction: column;
background-color: #ffffff;
padding: 16px;
width: 180px;
min-width: 180px;
box-sizing: border-box;
font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.menu a,
.menu button {
text-decoration: none;
color: #333;
padding: 12px;
margin: 8px 0;
display: flex;
align-items: center;
border-radius: 8px;
transition: background-color 0.3s;
font-size: 16px;
position: relative;
font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.menu a:hover,
.menu button:hover {
background-color: #E8F5FC;
}

.menu a.active {
background-color: #C5E5F5;
}

.menu a.active::after {
content: "";
position: absolute;
right: 0;
top: 50%;
transform: translateY(-50%);
width: 3px;
height: 60%;
background-color: #6FC9F2;
border-radius: 1.5px;
}

.menu i {
margin-right: 12px;
font-size: 20px;
color: #6FC9F2;
}

.menu button {
background: #6FC9F2;
border: none;
color: white;
justify-content: center;
font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.menu button:active {
background-color: #4EABD9;
}

.menu-logo {
display: flex;
align-items: center;
justify-content: center;
margin-bottom: 24px;
}

.menu-logo img {
width: 50px;
height: auto;
}