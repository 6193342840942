/* Profile.css */
.profile-container {
display: flex;
margin: 0 auto;
font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.menu {
flex: 0 0 200px;
height: 100%;
font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.profile-content {
flex-grow: 1;
padding: 20px;
font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.profile-title {
text-align: center;
margin-top: 10px;
margin-bottom: 20px;
color: #2c3e50; /* 見出しの文字色 */
font-size: 24px; /* 文字サイズを少し大きくする */
font-weight: bold;
padding: 10px; /* 見出しの上下に余白を追加 */
border-radius: 8px; /* 角を丸くする */
font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.profile-table {
width: 100%;
border-collapse: collapse;
font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.profile-table th,
.profile-table td {
padding: 12px;
text-align: left;
border-bottom: 1px solid #ccc;
font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.profile-table th {
background-color: #ecf0f1;
font-weight: bold;
font-size: 15px;
font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.profile-table tr:hover {
background-color: #f5f5f5;
font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.button-link {
display: inline-block;
padding: 8px 16px;
background-color: #007bff;
color: white;
text-align: center;
border-radius: 6px;
text-decoration: none;
font-size: 14px;
font-weight: 600;
transition: background-color 0.3s ease;
border: none;
cursor: pointer;
font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.button-link:hover {
background-color: #0056b3;
}

.button-link:active {
background-color: #003580;
}