/* ProductDetails.css */
/* 全体のレイアウト */
.layout {
  display: flex;
  font-family: 'NotoSansJP', sans-serif;
  min-height: 100vh; /* 全画面の高さを確保 */
}

/* メニュー */
.menu-container {
  flex: 0 0 200px; /* 他画面と同じ固定幅 */
  height: 100vh; /* メニューを全画面の高さに */
  background-color: #f9f9f9;
}

/* 商品詳細コンテンツ */
.product-details {
  flex-grow: 1; /* 残りのスペースを占有 */
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background: #fff;
  font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.product-details h1 {
  color: #333;
  font-size: 24px;
  margin-bottom: 20px;
  font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.product-details p {
  color: #666;
  line-height: 1.6;
  font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.product-details button {
  padding: 10px 20px;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.product-details button:hover {
  background-color: #0056b3;
}

/* 一覧に戻るセクション */
.back-to-list {
  margin-bottom: 20px;
}

.back-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #555;
  font-size: 1.0rem;
  font-weight: bold;
  font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.back-link:hover {
  color: #000;
}

.back-icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

