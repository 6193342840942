/* ResetPassword.css */
.reset-password-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 800px;
  margin: 40px auto;
  background-color: #ffffff;
  padding: 20px;
  font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.reset-password-form,
.reset-password-image {
  flex: 1;
  font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.reset-password-form {
  display: flex;
  flex-direction: column;
  align-items: center; /* 子要素を中央に配置 */
  font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.reset-password-form h1 {
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
  color: #222;
  font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.reset-password-form label {
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #444;
  font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.reset-password-form input[type="password"] {
  width: 100%; /* フォームフィールドの幅を100%に設定 */
  max-width: 400px; /* フォームフィールドの最大幅を設定 */
  padding: 10px 12px;
  margin-bottom: 20px;
  border: 2px solid #ccc;
  border-radius: 6px;
  font-size: 16px;
  color: #333;
  box-sizing: border-box; /* パディングとボーダーを含むボックスサイズを設定 */
  font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.reset-password-form button {
  display: block;
  width: 100%;
  max-width: 300px;  /* ボタンの最大幅を設定 */
  margin: 0 auto;   /* ボタンを中央に配置 */
  padding: 12px 0;
  background-color: #5c67f2;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.reset-password-form button:hover {
  background-color: #5058d4;
}

.reset-password-message {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  color: #666;
  font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.reset-password-error {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  color: red;
  font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.back-to-login {
  text-align: center;
  margin-top: 24px;
  font-size: 14px;
  color: #666;
  font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.back-to-login a {
  color: #5c67f2;
  text-decoration: none;
  font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.back-to-login a:hover {
  text-decoration: underline;
}

.signup-link {
  text-align: center;
  margin-top: 24px;
  font-size: 14px;
  color: #666;
  font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.signup-link .info-text {
  color: #666;
  font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.signup-link a {
  color: #5c67f2;
  text-decoration: none;
  font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.signup-link a:hover {
  text-decoration: underline;
}

.reset-password-image {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'NotoSansJP', sans-serif; /* フォントを適用 */
}

.reset-password-image img {
  max-width: 100%;
  height: auto;
}